import React, {useEffect, useState} from 'react';
import MenuItem from './MenuItem';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars, faMobile, faUsers, faXmark} from "@fortawesome/free-solid-svg-icons";
import {HashLink} from "react-router-hash-link";
import {createUseStyles} from "react-jss";
import {useTranslation} from "react-i18next";

const useStyles = createUseStyles({
    navbar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'transparent',
        color: 'var(--white)',
        height: 'var(--navbar-height)',
        width: '100%',
        position: 'fixed',
        top: 0,
        zIndex: 1000,
        transition: 'top 0.3s ease',
    },
    navbarHidden: {
        top: '-100px',
    },
    navbarOverlayOpen: {
        backgroundColor: 'var(--black)',
    },
    navbarLogo: {
        height: '35px',
        padding: 'var(--padding-standard)',
        marginLeft: 'calc(var(--margin-side) - var(--padding-standard))',
    },
    menuItems: {
        display: 'flex',
        borderRadius: '50px',
        gap: 'var(--margin-small)',
        '@media (max-width: 900px)': {
            display: 'none',
        },
    },
    rightContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: 'var(--margin-standard)',
    },
    downloadButton: {
        display: 'flex',
        alignItems: 'center',
        '@media (min-width: 901px)': {
            marginRight: 'var(--margin-side)',
        },
    },
    burgerMenu: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 'var(--padding-standard)',
        marginRight: 'calc(var(--margin-side) - var(--padding-standard))',
        borderRadius: '50%',
        width: '20px',
        height: '20px',
        '& .icon': {
            fontSize: 'var(--icon-size-standard)',
        },
        '@media (min-width: 900px)': {
            display: 'none',
        },
    },
    menuOverlay: {
        position: 'fixed',
        top: '-100%',
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'var(--black)',
        zIndex: 1000,
        transition: 'top 0.3s ease',
    },
    menuOverlayOpen: {
        top: 'var(--navbar-height)',
    },
});

const Navbar = () => {
    const {t} = useTranslation();
    const [isMenuOverlayOpen, setIsMenuOverlayOpen] = useState(false);
    const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
    const [visible, setVisible] = useState(true);
    const classes = useStyles();

    const toggleMenu = () => {
        setIsMenuOverlayOpen(!isMenuOverlayOpen);
    };

    const closeMenu = () => {
        setIsMenuOverlayOpen(false);
    };

    const handleScroll = () => {
        const currentScrollPos = window.scrollY;
        setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
        setPrevScrollPos(currentScrollPos);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [prevScrollPos, handleScroll]);

    const menuItems = [
            {to: '/#app', icon: <FontAwesomeIcon icon={faMobile}/>, text: t('common.navbar.app')},
            {to: '/for-influencer#start', icon: <FontAwesomeIcon icon={faUsers}/>, text: t('common.navbar.forInfluencers')},
        ]
    ;

    return (
        <nav
            className={`${classes.navbar} ${isMenuOverlayOpen ? classes.navbarOverlayOpen : ''} ${visible ? '' : classes.navbarHidden}`}>
            <HashLink smooth to="/#start" onClick={closeMenu}>
                <img src="/assets/images/logo/logo_horizontal.png" alt="Logo" className={classes.navbarLogo}/>
            </HashLink>
            <div className={classes.menuItems}>
                {menuItems.map((item) => (
                    <MenuItem to={item.to} icon={item.icon} text={item.text}
                              isButton={item.isButton}/>
                ))}
            </div>
            <div className={classes.rightContainer}>
                <div className={classes.downloadButton}>
                    <MenuItem to={'/download#start'} text={t('common.navbar.download')}
                              isButton={true}/>
                </div>
                <div className={classes.burgerMenu} onClick={toggleMenu}>
                    {isMenuOverlayOpen ? <FontAwesomeIcon icon={faXmark} className="icon"/> :
                        <FontAwesomeIcon icon={faBars} className="icon"/>}
                </div>
            </div>
            <div className={`${classes.menuOverlay} ${isMenuOverlayOpen ? classes.menuOverlayOpen : ''}`}
                 onClick={toggleMenu}>
                {menuItems.map((item) => (
                    <MenuItem to={item.to} icon={item.icon} text={item.text} isOverlay={true}
                              isButton={item.isButton}/>
                ))}
            </div>
        </nav>
    );
}

export default Navbar;