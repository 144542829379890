import {createUseStyles} from "react-jss";
import {useTranslation} from "react-i18next";
import React from "react";
import SectionHeader from "../../Common/SectionHeader";
import ReviewCard from "./ReviewCard";
import Carousel from "../../Common/Carousel/Carousel";

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: 'var(--white)',
    },
    swiperContainer: {
        width: '75%',
        '@media (max-width: 900px)': {
            width: '100%',
        },
    },
});

const ReviewSection = () => {
    const {t} = useTranslation();
    const classes = useStyles();

    const reviews = Array.from({length: 6}, (_, index) => ({
        userName: t(`userReviews.reviews.r${index + 1}.userName`),
        store: t(`userReviews.reviews.r${index + 1}.store`),
        title: t(`userReviews.reviews.r${index + 1}.title`),
        review: t(`userReviews.reviews.r${index + 1}.review`)
    }));

    return (
        <div className={classes.container}>
            <SectionHeader
                overline={t('userReviews.overline')}
                headline={t('userReviews.headline')}
                subHeadline={t('userReviews.subHeadline')}
            />
            <div className={classes.swiperContainer}>
                <Carousel swiperSlideComponent={ReviewCard} data={reviews}/>
            </div>
        </div>
    )
        ;
};

export default ReviewSection;