import React from 'react';
import {createUseStyles} from 'react-jss';
import Qa from './Qa';
import SectionHeader from "../SectionHeader";
import {useTranslation} from "react-i18next";

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        color: 'var(--white)',
        '@media (max-width: 900px)': {
            flexDirection: 'column',
        },
    },
    headerContainer: {
        '@media (min-width: 901px)': {
            marginRight: 'var(--margin-standard)',
        },
    },
    qaContainer: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        '@media (min-width: 901px)': {
            width: '50%',
        }
    },
});

const QaSection = ({qaData}) => {
    const {t} = useTranslation();
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.headerContainer}>
                <SectionHeader
                    overline={t('qa.overline')}
                    headline={t('qa.headline')}
                    subHeadline={t('qa.subHeadline')}/>
            </div>
            <div className={classes.qaContainer}>
                {qaData.map((qa, index) => (
                    <div key={index}>
                        <Qa question={qa.question} answer={qa.answer}/>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default QaSection;
