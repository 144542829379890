import React from 'react';
import {createUseStyles} from 'react-jss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faApple, faGooglePlay} from '@fortawesome/free-brands-svg-icons';
import {useTranslation} from 'react-i18next';

const useStyles = createUseStyles({
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        color: 'var(--white)',
        backgroundColor: 'var(--red)',
        padding: 'var(--padding-small)',
        borderRadius: '15px',
        textDecoration: 'none',
        width: '180px',
        height: '40px',
        cursor: 'pointer',
        transition: 'filter 0.3s',
        '&:hover': {
            filter: 'brightness(0.9)',
        },
        '@media (max-width: 900px)': {
            width: '160px',
        },
    },
    icon: {
        margin: '0 var(--margin-standard)',
        fontSize: 'var(--icon-size-standard)',
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        fontSize: 'var(--font-size-small)',
        fontWeight: 'bold',
        marginRight: 'var(--margin-standard)',
    },
    smallText: {
        fontSize: 'var(--font-size-very-small)',
        fontWeight: 'normal',
    },
});

const DownloadButton = ({store}) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const icon = store === 'appStore' ? faApple : faGooglePlay;
    const storeLink = store === 'appStore'
        ? 'https://apps.apple.com/de/app/gymky/id6470682855'
        : 'https://play.google.com/store/apps/details?id=com.profitcorp.gymky&hl=gsw&gl=US';

    return (
        <a href={storeLink} className={classes.buttonContainer} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={icon} className={classes.icon}/>
            <div className={classes.textContainer}>
                <span className={classes.smallText}>{t('common.downloadButton.downloadIn')}</span>
                <span>{store === 'appStore' ? 'App Store' : 'Google Play'}</span>
            </div>
        </a>
    );
};

export default DownloadButton;