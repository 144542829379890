import React from 'react';
import {createUseStyles} from 'react-jss';
import FeatureOverview from "../FeatureOverview";
import KeyFigureSection from "../KeyFigures/KeyFigureSection";

const useStyles = createUseStyles({
    headingContainer: {
        position: 'relative',
        width: '100%',
        top: 0,
        backgroundColor: 'var(--dark-grey)',
    },
    heading: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        margin: '0 var(--margin-side)',
    },
    header: {
        marginTop: 'calc(var(--navbar-height) + var(--margin-large))',
        '@media (min-width: 901px)': {
            marginBottom: 'var(--margin-large)',
        },
    },
});

const KeyFiguresHeading = ({headline, subHeadline, buttons, gifSrc, keyFigures}) => {
    const classes = useStyles();

    return (
        <div className={classes.headingContainer}>
            <div className={classes.heading}>
                <div className={classes.header}>
                    <FeatureOverview
                        headline={headline}
                        subHeadline={subHeadline}
                        flyIn={false}
                        buttons={buttons}
                        gifSrc={gifSrc}
                        showPhoneContainerMobile={false}
                    />
                </div>
                <KeyFigureSection keyFigures={keyFigures}/>
            </div>
        </div>
    );
};

export default KeyFiguresHeading;
