import React from 'react';
import {createUseStyles} from 'react-jss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faStar} from '@fortawesome/free-solid-svg-icons';

const useStyles = createUseStyles({
    container: {
        padding: 'var(--padding-standard)',
        borderRadius: '15px',
        transition: 'transform 0.5s ease',
        color: 'var(--black)',
        backgroundColor: 'var(--light-grey)',
    },
    starsContainer: {
        display: 'flex',
        color: 'var(--gold)',
        margin: 'var(--margin-small)',
    },
    title: {
        fontSize: 'var(--font-size-standard)',
        fontWeight: 'bold',
        margin: 'var(--margin-small)'
    },
    review: {
        fontSize: 'var(--font-size-small)',
        margin: 'var(--margin-small)',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 6,
        '-webkit-box-orient': 'vertical',
    },
    author: {
        fontSize: 'var(--font-size-very-small)',
        fontWeight: 'bold',
        overflow: 'hidden',
        margin: 'var(--margin-small)',
        color: 'var(--dark-grey)'
    },
});

const ReviewCard = ({userName, store, title, review}) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.starsContainer}>
                {[...Array(5)].map((_, index) => (
                    <FontAwesomeIcon key={index} icon={faStar}/>
                ))}
            </div>
            <div className={classes.title}>{title}</div>
            <div className={classes.review}>{review}</div>
            <div className={classes.author}>@{userName} · {store}</div>
        </div>
    )
        ;
};

export default ReviewCard;