import FeatureOverview from "./FeatureOverview";
import React from "react";
import {useTranslation} from "react-i18next";

const AppOverviewSection = () => {
    const {t} = useTranslation();

    return (
        <div>
            <FeatureOverview
                overline={t("common.features.socializing.overline")}
                headline={t("common.features.socializing.headline")}
                keypoints={[
                    t("common.features.socializing.keypoints.k1"),
                    t("common.features.socializing.keypoints.k2"),
                    t("common.features.socializing.keypoints.k3"),
                ]}
                gifSrc="/assets/images/Feed.gif"
                mirrorLayout={true}
                centerContent={true}
            />
            <FeatureOverview
                overline={t("common.features.logging.overline")}
                headline={t("common.features.logging.headline")}
                keypoints={[
                    t("common.features.logging.keypoints.k1"),
                    t("common.features.logging.keypoints.k2"),
                ]}
                gifSrc="/assets/images/Measure.gif"
                mirrorLayout={false}
                centerContent={true}
            />
            <FeatureOverview
                overline={t("common.features.workouts.overline")}
                headline={t("common.features.workouts.headline")}
                keypoints={[
                    t("common.features.workouts.keypoints.k1"),
                    t("common.features.workouts.keypoints.k2"),
                ]}
                gifSrc="/assets/images/Workouts.gif"
                mirrorLayout={true}
                centerContent={true}
            />
            <FeatureOverview
                overline={t("common.features.exercises.overline")}
                headline={t("common.features.exercises.headline")}
                keypoints={[
                    t("common.features.exercises.keypoints.k1"),
                    t("common.features.exercises.keypoints.k2"),
                ]}
                gifSrc="/assets/images/Exercises.gif"
                mirrorLayout={false}
                centerContent={true}
            />
            <FeatureOverview
                overline={t("common.features.qa.overline")}
                headline={t("common.features.qa.headline")}
                keypoints={[
                    t("common.features.qa.keypoints.k1"),
                ]}
                gifSrc="/assets/images/Questions.gif"
                mirrorLayout={true}
                centerContent={true}
            />
        </div>
    );
};

export default AppOverviewSection;