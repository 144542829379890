import React from 'react';
import {createUseStyles} from 'react-jss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleCheck} from '@fortawesome/free-solid-svg-icons';

const useStyles = createUseStyles({
    keypointsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    keypoint: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 'var(--font-size-small)',
        marginBottom: 'var(--margin-small)',
    },
    keypointIcon: {
        color: 'var(--red)',
        marginRight: 'var(--margin-standard)',
    },
});

const KeypointsList = ({keypoints, size = 'var(--font-size-small)', margin = 'var(--margin-small)'}) => {
    const classes = useStyles();

    return (
        <div className={classes.keypointsContainer}>
            {keypoints.map((keypoint, index) => (
                <div key={index} className={classes.keypoint} style={{fontSize: size, marginBottom: margin}}>
                    <FontAwesomeIcon icon={faCircleCheck} className={classes.keypointIcon}/>
                    <span>{keypoint}</span>
                </div>
            ))}
        </div>
    );
};

export default KeypointsList;
