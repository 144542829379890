import {createUseStyles} from "react-jss";
import React from "react";
import Argument from "./Argument";
import SectionHeader from "../SectionHeader";
import {useTranslation} from "react-i18next";

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: 'var(--white)',
    },
    argumentsContainer: {
        display: 'grid',
        gridAutoFlow: 'column',
        gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
        justifyContent: 'center',
        gap: 'var(--margin-standard)',
        width: '80%',
        '@media (max-width: 900px)': {
            gridAutoFlow: 'row',
            gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
            width: '100%',
        },
    },
});

const ArgumentSection = ({argumentsData}) => {
    const {t} = useTranslation();
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <SectionHeader
                headline={t('forInfluencerPage.argumentSection.headline')}
            />
            <div className={classes.argumentsContainer}>
                {argumentsData.map((argument, index) => (
                    <div key={index}>
                        <Argument icon={argument.icon} headline={argument.headline} text={argument.text}
                                  keypoints={argument.keypoints}/>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ArgumentSection;