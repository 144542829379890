import React, {useEffect} from 'react';
import {createUseStyles} from 'react-jss';
import {useTranslation} from "react-i18next";
import Contact from "../components/Common/Contact";
import ContentSection from "../components/Footer/ContentSection";

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    contentContainer: {
        width: '50%',
        color: 'var(--white)',
        margin: 'var(--navbar-height) var(--margin-side)',
        '@media (max-width: 900px)': {
            width: 'calc(100% - 2*var(--margin-side))',
        },
    },
    h1: {
        fontSize: 'var(--font-size-large)',
        fontWeight: 'bold',
        marginTop: 'var(--margin-large)'
    },
});

const CommunityGuidelines = () => {
    const {t} = useTranslation();
    const classes = useStyles();

    useEffect(() => {
        document.title = t('communityGuidelines.title')
    });

    return (
        <div className={classes.container}>
            <div className={classes.contentContainer}>
                <h1 className={classes.h1}>{t('communityGuidelines.title')}</h1>
                <ContentSection
                    id="abridged-version"
                    title={t('communityGuidelines.abridgedVersion.title')}
                    content={[
                        {text: t('communityGuidelines.abridgedVersion.textSections.s1')},
                    ]}
                />
                <ContentSection
                    id="read-version"
                    title={t('communityGuidelines.readVersion.title')}
                    content={[
                        {text: t('communityGuidelines.readVersion.textSections.s1')},
                        [
                            t('communityGuidelines.readVersion.textSections.s2'),
                            t('communityGuidelines.readVersion.textSections.s3'),
                            t('communityGuidelines.readVersion.textSections.s4'),
                            t('communityGuidelines.readVersion.textSections.s5'),
                            t('communityGuidelines.readVersion.textSections.s6'),
                            t('communityGuidelines.readVersion.textSections.s7'),
                            t('communityGuidelines.readVersion.textSections.s8'),
                            t('communityGuidelines.readVersion.textSections.s9'),
                            t('communityGuidelines.readVersion.textSections.s10'),
                            t('communityGuidelines.readVersion.textSections.s11'),
                            t('communityGuidelines.readVersion.textSections.s12'),
                            t('communityGuidelines.readVersion.textSections.s13'),
                        ],
                        {text: t('communityGuidelines.readVersion.textSections.s14')},
                        {text: t('communityGuidelines.readVersion.textSections.s15')},
                        {text: t('communityGuidelines.readVersion.textSections.s16')},
                        {text: t('communityGuidelines.readVersion.textSections.s17')},
                    ]}
                />
            </div>
            <Contact headline={t('common.contactSection.headline')} name={"Benedikt Lüth"}
                     picture={"/assets/images/team/benedikt-lueth.jpg"}
                     role={'Community Manager'}
                     email={"support@gymble.fit"}/>
        </div>
    );
}

export default CommunityGuidelines;
