import React from 'react';
import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
    overline: {
        fontSize: 'var(--font-size-small)',
        fontWeight: 'bold',
        marginBottom: 'var(--margin-standard)',
    },
    headline: {
        fontSize: 'var(--font-size-large)',
        fontWeight: 'bold',
        marginBottom: 'var(--margin-standard)',
    },
    subHeadline: {
        fontSize: 'var(--font-size-standard)',
        fontWeight: 'normal',
        marginBottom: 'var(--margin-standard)',
    },
});

const SectionHeader = ({overline, headline, subHeadline}) => {
    const classes = useStyles();

    return (
        <div>
            {overline && (<div className={classes.overline}>{overline}</div>)}
            {headline && (<div className={classes.headline}>{headline}</div>)}
            {subHeadline && (<div className={classes.subHeadline}>{subHeadline}</div>)}
        </div>
    );
};

export default SectionHeader;