import React from 'react';
import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
    icon: {
        marginBottom: 'var(--margin-standard)',
        transition: 'transform 0.3s ease',
        userSelect: 'none',
        '&:hover': {
            transform: 'rotate(-20deg) translateY(-20%)',
        }
    },
});

const Smiley = ({
                    smiley,
                    size = 'var(--icon-size-super-large)',
                }) => {
    const classes = useStyles();

    return (
        <div className={classes.icon} style={{fontSize: size}}>{smiley}</div>

    );
};

export default Smiley;
