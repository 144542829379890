import React from 'react';
import {createUseStyles} from 'react-jss';
import SectionHeader from "./SectionHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";

const useStyles = createUseStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: 'var(--white)',
        },
        contactContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            '@media (max-width: 900px)': {
                flexDirection: 'column',
            },
        },
        profilePicture: {
            width: '150px',
            height: '150px',
            borderRadius: '50%',
            transition: 'transform 0.5s ease',
            '&:hover': {
                transform: 'scale(1.05)',
            },
            '@media (min-width: 901px)': {
                marginRight: 'var(--margin-large)',
            },
            '@media (max-width: 900px)': {
                marginBottom: 'var(--margin-standard)',
                width: '100px',
                height: '100px',
            },
        },
        contactInfoContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            '@media (max-width: 900px)': {
                alignItems: 'center',
            },
        },
        name: {
            fontSize: 'var(--font-size-standard)',
            fontWeight: 'bold',
            marginBottom: 'var(--margin-small)',
        },
        role: {
            fontSize: 'var(--font-size-small)',
            marginBottom: 'var(--margin-small)',
            color: 'var(--grey)',
        },
        buttonContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            color: 'var(--white)',
            backgroundColor: 'var(--red)',
            padding: 'var(--padding-standard) var(--padding-large)',
            borderRadius: '10px',
            textDecoration: 'none',
            fontSize: 'var(--font-size-small)',
            fontWeight: 'bold',
            cursor: 'pointer',
            transition: 'filter 0.3s',
            '&:hover': {
                filter: 'brightness(0.9)',
            },
        },
        icon: {
            marginRight: 'var(--margin-standard)',
            fontSize: 'var(--icon-size-standard)',
        },
    }
);

const Contact = ({headline, name, role, picture, email}) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <SectionHeader headline={headline}/>
            <div className={classes.contactContainer}>
                <img src={picture} alt={name} className={classes.profilePicture}/>
                <div className={classes.contactInfoContainer}>
                    <div className={classes.name}>{name}</div>
                    {role && <div className={classes.role}>{role}</div>}
                    <a href={`mailto:${email}`}
                       className={classes.buttonContainer}
                       target="_blank"
                       rel="noopener noreferrer"
                    >
                        <FontAwesomeIcon icon={faEnvelope} className={classes.icon}/>
                        <span>Contact now</span>
                    </a>
                </div>
            </div>
        </div>
    )
        ;
};

export default Contact;
