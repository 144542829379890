import React from 'react';
import {createUseStyles} from 'react-jss';
import SectionHeader from "../../Common/SectionHeader";
import PhoneContainer from "../../Common/PhoneContainer";

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        gap: 'var(--margin-large)',
        alignItems: 'flex-start',
        textDecoration: 'none',
        padding: 'var(--padding-large)',
        borderRadius: '20px',
        color: 'var(--white)',
        backgroundColor: 'var(--dark-grey)',
        '&:hover': {
            color: 'var(--black)',
            backgroundColor: 'var(--light-grey)',
        },
        '@media (max-width: 900px)': {
            flexDirection: 'column',
            alignItems: 'center',
            gap: 'var(--margin-standard)',
        },
    },
});

const InfluencerTestimonial = ({influencerImage, name, contentCategory, url, text}) => {
    const classes = useStyles();
    return (
        <a href={url} target='_blank' rel='noreferrer' className={classes.container}>
            <div className={classes.imageContainer}>
                <PhoneContainer gifSrc={influencerImage} suppressScale={true}/>
            </div>
            <div className={classes.textContainer}>
                <SectionHeader overline={contentCategory} headline={name} subHeadline={text}/>
            </div>
        </a>
    );
};

export default InfluencerTestimonial;
