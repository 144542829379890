import React from 'react';
import {useCookies} from 'react-cookie';
import {createUseStyles} from 'react-jss';
import {useTranslation} from "react-i18next";

const useStyles = createUseStyles({
    container: {
        position: 'fixed',
        bottom: 0,
        color: 'var(--black)',
        backgroundColor: 'var(--light-grey)',
        padding: 'var(--padding-standard)',
        zIndex: 9999,
        '@media (max-width: 900px)': {
            padding: 'var(--padding-large)',
        }
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: 'var(--margin-large)',
        '@media (max-width: 900px)': {
            flexDirection: 'column',
            gap: 'var(--margin-small)',
        }
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 'var(--margin-small)',
    },
    headline: {
        fontSize: 'var(--font-size-small)',
        fontWeight: 'bold',
    },
    text: {
        fontSize: 'var(--font-size-very-small)',
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: 'var(--margin-standard)',
        alignItems: 'center',
        justifyContent: 'center',
    },
    button: {
        color: 'var(--white)',
        backgroundColor: 'var(--red)',
        fontSize: 'var(--font-size-small)',
        fontWeight: 'bold',
        borderRadius: '10px',
        border: '2px solid var(--red)',
        padding: 'var(--padding-standard) var(--padding-large)',
        cursor: 'pointer',
        '&.reverted': {
            color: 'var(--red)',
            backgroundColor: 'transparent',
        }
    },
});

const CookieConsent = () => {
    const {t} = useTranslation();
    const [cookies, setCookie] = useCookies(['cookieConsent']);
    const classes = useStyles();

    const handleAccept = () => {
        setCookie('cookieConsent', true, {path: '/'});
    };

    const handleDecline = () => {
        setCookie('cookieConsent', false, {path: '/'});
    };

    const showCookieBanner = cookies.cookieConsent === undefined;

    if (!showCookieBanner) {
        return null;
    }

    return (
        <div className={classes.container}>
            <div className={classes.contentContainer}>
                <div className={classes.textContainer}>
                    <div className={classes.headline}>{t('common.cookiesConsent.title')}</div>
                    <div
                        className={classes.text}>{t('common.cookiesConsent.text')}</div>
                </div>
                <div className={classes.buttonContainer}>
                    <button className={classes.button}
                            onClick={handleAccept}>{t('common.cookiesConsent.accept')}</button>
                    <button className={`${classes.button} reverted`}
                            onClick={handleDecline}>{t('common.cookiesConsent.decline')}</button>
                </div>
            </div>
        </div>
    );
};

export default CookieConsent;