import React from 'react';
import {createUseStyles} from 'react-jss';
import DownloadButton from "./DownloadButton";
import {useTranslation} from 'react-i18next';
import Smiley from "../Smiley";

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '20px',
        padding: 'var(--padding-super-large)',
        marginBottom: 'var(--margin-large)',
        color: 'var(--white)',
        backgroundColor: 'var(--dark-grey)',
    },
    headline: {
        width: '90%',
        fontSize: 'var(--font-size-super-large)',
        fontWeight: 'bold',
        marginBottom: 'var(--margin-standard)',
        textAlign: 'center',
    },
    downloadButtonContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: 'var(--margin-standard)',
    },
});

const DownloadSection = () => {
    const {t} = useTranslation();
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Smiley smiley={'🚀'}/>
            <div className={classes.headline}>{t('common.downloadSection.headline')}</div>
            <div className={classes.downloadButtonContainer}>
                <DownloadButton store={"appStore"}/>
                <DownloadButton store={"playStore"}/>
            </div>
        </div>

    );
};

export default DownloadSection;