import React from 'react';
import {createUseStyles} from 'react-jss';
import Smiley from "../Smiley";
import KeypointsList from "../KeypointsList";

const useStyles = createUseStyles({
    container: {
        padding: 'var(--padding-standard)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    headline: {
        fontSize: 'var(--font-size-standard)',
        fontWeight: 'bold',
    },
    text: {
        fontSize: 'var(--font-size-standard)',
        marginTop: 'var(--margin-small)',
    },
});

const Argument = ({icon, headline, text, keypoints}) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Smiley smiley={icon}/>
            <div className={classes.headline}>{headline}</div>
            {text && (<div className={classes.text}>{text}</div>)}
            {keypoints && keypoints.length > 0 && (
                <KeypointsList keypoints={keypoints}/>
            )}
        </div>
    );
};

export default Argument;