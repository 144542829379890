import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import SectionHeader from './SectionHeader';
import {useInView} from 'react-intersection-observer';
import KeypointsList from "./KeypointsList";
import PhoneContainer from "./PhoneContainer";

const useStyles = createUseStyles({
        container: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'var(--white)',
            visibility: 'hidden',
            transform: props => props.flyIn ? 'translateY(50px)' : 'none',
            transition: 'transform 0.9s, visibility 0.5s',
            '&.isVisible': {
                visibility: 'visible',
                transform: 'translateY(0)',
            },
            '&.mirrorLayout': {
                '@media (min-width: 901px)': {
                    flexDirection: 'row-reverse',
                },
            },
            '&.centerContent': {
                '@media (min-width: 901px)': {
                    gap: 'var(--margin-very-large)',
                },
            },
            '@media (max-width: 900px)': {
                flexDirection: 'column',
                alignItems: 'center',
                marginBottom: 'var(--margin-standard)',
            },
        },
        textContainer: {
            display: 'flex',
            flexDirection: 'column',
            '&:not(.centerContent)': {
                flex: '1 1 50%',
            },
            '@media (min-width: 901px)': {
                marginRight: 'var(--margin-large)',
                maxWidth: '50%',
            },
            '@media (max-width: 901px)': {
                marginBottom: 'var(--margin-standard)',
            },
        },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            gap: 'var(--margin-standard)',
            '@media (max-width: 900px)': {
                flexDirection: 'column',
                alignItems: 'center',
                marginBottom: 'var(--margin-standard)',
            },
        },
        gifContainer: {
            display: 'flex',
            justifyContent: 'center',
            '&:not(.centerContent)': {
                flex: '1 1 50%',
            },
            '@media (max-width: 900px)': {
                order: '1',
                marginBottom: 'var(--margin-standard)',
                '&.hide': {
                    display: 'none',
                },
            },
        },
    })
;

const FeatureOverview = ({
                             overline,
                             headline,
                             subHeadline,
                             keypoints,
                             gifSrc,
                             mirrorLayout,
                             buttons,
                             flyIn = true,
                             showPhoneContainerMobile = true,
                             centerContent = false,
                         }) => {
    const classes = useStyles({flyIn});
    const [isVisible, setIsVisible] = useState(false);
    const [ref, inView] = useInView({
        threshold: 0.25,
    });


    useEffect(() => {
        if (inView) {
            setIsVisible(true);
        }
    }, [inView]);

    return (
        <div
            className={`${classes.container} ${isVisible ? 'isVisible' : ''} ${mirrorLayout ? 'mirrorLayout' : ''} ${centerContent ? 'centerContent' : ''}`}
            ref={ref}
        >
            <div className={`${classes.textContainer} ${centerContent ? 'centerContent' : ''}`}>
                <SectionHeader overline={overline} headline={headline} subHeadline={subHeadline}/>
                {keypoints && keypoints.length > 0 && (
                    <KeypointsList keypoints={keypoints} size={"var(--font-size-standard)"}
                                   margin={"var(--margin-standard"}/>
                )}
                {buttons && buttons.length > 0 && (
                    <div className={classes.buttonContainer}>
                        {buttons.map((button, index) => (
                            <React.Fragment key={index}>
                                {button}
                            </React.Fragment>
                        ))}
                    </div>
                )}
            </div>
            <div
                className={`${classes.gifContainer} ${!showPhoneContainerMobile ? 'hide' : ''} ${centerContent ? 'centerContent' : ''}`}>
                <PhoneContainer gifSrc={gifSrc}/>
            </div>
        </div>
    );
};

export default FeatureOverview;
