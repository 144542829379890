import React from 'react';
import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
    container: {
        width: '260px',
        height: 'auto',
        borderRadius: '30px',
        transition: 'transform 0.5s ease',
        '&:not(.suppressScale):hover': {
            transform: 'scale(1.1)',
        },
    },
});

const PhoneContainer = ({gifSrc, suppressScale = false}) => {
    const classes = useStyles();

    let containerClass = classes.container;
    if (suppressScale) containerClass += ' suppressScale';

    return (
        <img className={containerClass} src={gifSrc} alt={""}/>
    );
};

export default PhoneContainer;
