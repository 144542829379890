import {createUseStyles} from "react-jss";
import {useTranslation} from "react-i18next";
import React from "react";
import SectionHeader from "../../Common/SectionHeader";
import InfluencerTestimonial from "./InfluencerTestimonial";
import Carousel from "../../Common/Carousel/Carousel";

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: 'var(--white)',
    },
    swiperContainer: {
        width: '90%',
        '@media (max-width: 900px)': {
            width: '100%',
        },
    },
});

const InfluencerTestimonialSection = () => {
    const {t} = useTranslation();
    const classes = useStyles();

    const testimonialData = [
        {
            influencerImage: "/assets/images/Feed.gif",
            name: t('forInfluencerPage.testimonialSection.testimonials.t1.name'),
            contentCategory: t('forInfluencerPage.testimonialSection.testimonials.t1.contentCategory'),
            url: 'https://example.com',
            text: t('forInfluencerPage.testimonialSection.testimonials.t1.text'),
        },
        {
            influencerImage: "/assets/images/Feed.gif",
            name: t('forInfluencerPage.testimonialSection.testimonials.t2.name'),
            contentCategory: t('forInfluencerPage.testimonialSection.testimonials.t2.contentCategory'),
            url: 'https://example.com',
            text: t('forInfluencerPage.testimonialSection.testimonials.t2.text'),
        },
        {
            influencerImage: "/assets/images/Feed.gif",
            name: t('forInfluencerPage.testimonialSection.testimonials.t3.name'),
            contentCategory: t('forInfluencerPage.testimonialSection.testimonials.t3.contentCategory'),
            url: 'https://example.com',
            text: t('forInfluencerPage.testimonialSection.testimonials.t3.text'),
        },
        {
            influencerImage: "/assets/images/Feed.gif",
            name: t('forInfluencerPage.testimonialSection.testimonials.t1.name'),
            contentCategory: t('forInfluencerPage.testimonialSection.testimonials.t1.contentCategory'),
            url: 'https://example.com',
            text: t('forInfluencerPage.testimonialSection.testimonials.t1.text'),
        },
    ];

    return (
        <div className={classes.container}>
            <SectionHeader
                headline={t('forInfluencerPage.testimonialSection.headline')}
            />
            <div className={classes.swiperContainer}>
                <Carousel swiperSlideComponent={InfluencerTestimonial}
                          data={testimonialData}
                          autoplay={true}
                          autoplayDelay={10000}
                          slidesPerViewDesktop={2}
                />
            </div>
        </div>
    );
};

export default InfluencerTestimonialSection;