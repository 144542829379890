import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDown} from '@fortawesome/free-solid-svg-icons';
import {createUseStyles} from 'react-jss';
import {supportedLngs} from '../../config/i18n';

const useStyles = createUseStyles({
        localeSwitcher: {
            position: 'relative',
            cursor: 'pointer',
        },
        icon: {
            transition: 'transform 0.3s ease',
        },
        currentLanguage: {
            padding: 'var(--padding-standard)',
            alignItems: 'flex-start',
        },
        currentLanguageText: {
            marginRight: 'var(--margin-small)',
            fontSize: 'var(--font-size-small)',
            fontWeight: 'bold',
        },
        languagePopup: {
            position: 'absolute',
            bottom: 'calc(100% + 5px)',
            left: 0,
            zIndex: 1000,
            backgroundColor: 'var(--white)',
            borderRadius: '5px',
            transition: 'transform 0.3s ease',
            transformOrigin: 'top',
            transform: 'scaleY(0)',
            visibility: 'hidden',
            opacity: 0,
        },
        languagePopupOpen: {
            transform: 'scaleY(1)',
            visibility: 'visible',
            opacity: 1,
        },
        popupContent: {
            maxHeight: 200,
            overflowY: 'auto',
            margin: 'var(--margin-small) 0',
            textAlign: 'left',
            '&::-webkit-scrollbar': {
                width: '5px',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'var(--grey)',
                borderRadius: '5px',
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: 'var(--light-grey)',
                borderRadius: '5px',
            },
        },
        languageOption:
            {
                padding: 'var(--padding-small)',
                color:
                    'var(--black)',
                '&:hover':
                    {
                        color: 'var(--red)',
                    }
                ,
            }
    })
;

const LocaleSwitcher = () => {
    const {i18n} = useTranslation();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const classes = useStyles();

    const handleToggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleLanguageChange = (language) => {
        i18n.changeLanguage(language);
        setIsDropdownOpen(false);
    };

    return (
        <div className={classes.localeSwitcher}>
            <div className={classes.currentLanguage} onClick={handleToggleDropdown}>
                <span className={classes.currentLanguageText}>{supportedLngs[i18n.language]}</span>
                <FontAwesomeIcon icon={faAngleDown} className={classes.icon} style={{
                    transform: isDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                }}/>
            </div>
            <div className={`${classes.languagePopup} ${isDropdownOpen ? classes.languagePopupOpen : ''}`}>
                <div className={classes.popupContent}>
                    {Object.entries(supportedLngs).map(([code, name]) => (
                        <div
                            key={code}
                            className={classes.languageOption}
                            onClick={() => handleLanguageChange(code)}
                        >
                            {name}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LocaleSwitcher;