import React, {useState} from 'react';
import {createUseStyles} from 'react-jss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDown} from '@fortawesome/free-solid-svg-icons';

const useStyles = createUseStyles({
    qaContainer: {
        padding: 'var(--padding-small)',
        marginBottom: 'var(--margin-standard)',
        borderRadius: '10px',
        color: 'var(--white)',
        backgroundColor: 'var(--dark-grey)',
        '&:hover': {
            color: 'var(--black)',
            backgroundColor: 'var(--light-grey)',
        },
    },
    questionContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 'var(--padding-standard)',
        cursor: 'pointer',
    },
    question: {
        fontSize: 'var(--font-size-small)',
        fontWeight: 'bold',
        marginRight: 'var(--margin-standard)',
    },
    icon: {
        transition: 'transform 0.3s ease',
    },
    answer: {
        display: 'none',
        padding: 'var(--padding-standard)',
        fontSize: 'var(--font-size-small)',
    },
    answerOpen: {
        display: 'block',
    },
});

const Qa = ({question, answer}) => {
    const [isAnswerOpen, setIsAnswerOpen] = useState(false);
    const classes = useStyles();

    const handleToggleAnswer = () => {
        setIsAnswerOpen(!isAnswerOpen);
    };

    return (
        <div className={classes.qaContainer}>
            <div className={classes.questionContainer} onClick={handleToggleAnswer}>
                <div className={classes.question}>{question}</div>
                <FontAwesomeIcon
                    icon={faAngleDown}
                    className={classes.icon}
                    style={{transform: isAnswerOpen ? 'rotate(180deg)' : 'rotate(0deg)'}}
                />
            </div>
            <div className={`${classes.answer} ${isAnswerOpen ? classes.answerOpen : ''}`}>
                {answer}
            </div>
        </div>
    );
};

export default Qa;
