import React from 'react';
import {createUseStyles} from 'react-jss';
import KeyFigure from "./KeyFigure";

const useStyles = createUseStyles({
    keyFigureSection: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
        gap: 'var(--margin-standard)',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 'var(--padding-large) 0',
    },
});

const KeyFigureSection = ({keyFigures}) => {
    const classes = useStyles();

    return (
        <div className={classes.keyFigureSection}>
            {keyFigures.map((keyFigure, index) => (
                <KeyFigure key={index} number={keyFigure.number} description={keyFigure.description}/>
            ))}
        </div>
    );
};

export default KeyFigureSection;
