import React, {useEffect} from 'react';
import {createUseStyles} from 'react-jss';
import {useTranslation} from "react-i18next";
import ContentSection from "../components/Footer/ContentSection";

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    imprintContainer: {
        width: '50%',
        color: 'var(--white)',
        margin: 'var(--navbar-height) var(--margin-side)',
        '@media (max-width: 900px)': {
            width: '100%',
        },
    },
    h1: {
        fontSize: 'var(--font-size-large)',
        fontWeight: 'bold',
        marginTop: 'var(--margin-large)'
    },
});

const Imprint = () => {
    const {t} = useTranslation();
    const classes = useStyles();

    useEffect(() => {
        document.title = t('imprintPage.title')
    });

    return (
        <div className={classes.container}>
            <div className={classes.imprintContainer}>
                <h1 className={classes.h1}>{t('imprintPage.title')}</h1>

                <ContentSection
                    id="website-operator"
                    title={t('imprintPage.operator.title')}
                    content={[
                        {text: t('imprintPage.operator.companyName'), isStrong: true},
                        {text: t('imprintPage.operator.address.street'), hasNoMargin: true},
                        {text: t('imprintPage.operator.address.city'), hasNoMargin: true},
                        {text: t('imprintPage.operator.address.country'), hasNoMargin: true},
                        {text: t('imprintPage.operator.email')},
                        {text: t('imprintPage.operator.representedBy.title'), isStrong: true},
                        {text: t('imprintPage.operator.representedBy.text')},
                        {
                            text: t('imprintPage.operator.responsibleForContent'),
                            isStrong: true
                        },
                        {text: t('imprintPage.operator.representedBy.text')},
                    ]}
                />
                <ContentSection
                    id="content-disclaimer"
                    title={t('imprintPage.contentDisclaimer.title')}
                    content={[
                        {text: t('imprintPage.contentDisclaimer.content.title'), isStrong: true},
                        {text: t('imprintPage.contentDisclaimer.content.text')},
                        {text: t('imprintPage.contentDisclaimer.links.title'), isStrong: true},
                        {text: t('imprintPage.contentDisclaimer.links.text')},
                    ]}
                />
                <ContentSection
                    id="copyright"
                    title={t('imprintPage.copyright.title')}
                    content={[
                        {text: t('imprintPage.copyright.text')},
                    ]}
                />
            </div>
        </div>
    );
}

export default Imprint;
