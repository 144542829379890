import React, {useEffect} from 'react';
import {createUseStyles} from 'react-jss';
import {useTranslation} from "react-i18next";
import Contact from "../components/Common/Contact";
import ContentSection from "../components/Footer/ContentSection";

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    dataPrivacyContainer: {
        width: '50%',
        color: 'var(--white)',
        margin: 'var(--navbar-height) var(--margin-side)',
        '@media (max-width: 900px)': {
            width: 'calc(100% - 2*var(--margin-side))',
        },
    },
    h1: {
        fontSize: 'var(--font-size-large)',
        fontWeight: 'bold',
        marginTop: 'var(--margin-large)'
    },
    timestamp: {
        marginTop: 'var(--margin-large)',
        fontSize: 'var(--font-size-small)',
        color: 'var(--grey)'
    },
});

const DataPrivacy = () => {
    const {t} = useTranslation();
    const classes = useStyles();

    useEffect(() => {
        document.title = t('dataPrivacyPage.title')
    });

    return (
        <div className={classes.container}>
            <div className={classes.dataPrivacyContainer}>
                <h1 className={classes.h1}>{t('dataPrivacyPage.title')}</h1>
                <ContentSection
                    id="operator-and-responsible"
                    title={t('dataPrivacyPage.operatorAndResponsible.title')}
                    content={[
                        {text: t('dataPrivacyPage.operatorAndResponsible.companyName'), isStrong: true},
                        {text: t('dataPrivacyPage.operatorAndResponsible.address.street'), hasNoMargin: true},
                        {text: t('dataPrivacyPage.operatorAndResponsible.address.city'), hasNoMargin: true},
                        {text: t('dataPrivacyPage.operatorAndResponsible.address.country'), hasNoMargin: true},
                        {text: t('dataPrivacyPage.operatorAndResponsible.email')},
                        {text: t('dataPrivacyPage.operatorAndResponsible.representedBy.title'), isStrong: true},
                        {text: t('dataPrivacyPage.operatorAndResponsible.representedBy.text')},
                        {
                            text: t('dataPrivacyPage.operatorAndResponsible.responsibleForDataPrivacy.title'),
                            isStrong: true
                        },
                        {text: t('dataPrivacyPage.operatorAndResponsible.responsibleForDataPrivacy.text')},
                    ]}
                />
                <ContentSection
                    id="types-of-collected-data"
                    title={t('dataPrivacyPage.typesOfCollectedData.title')}
                    content={[
                        {text: t('dataPrivacyPage.typesOfCollectedData.textSections.s1')},
                        [
                            t('dataPrivacyPage.typesOfCollectedData.textSections.s2'),
                            t('dataPrivacyPage.typesOfCollectedData.textSections.s3'),
                        ],
                        {text: t('dataPrivacyPage.typesOfCollectedData.textSections.s4')},
                        {text: t('dataPrivacyPage.typesOfCollectedData.textSections.s5')},
                    ]}
                />
                <ContentSection
                    id="types-of-data-processing"
                    title={t('dataPrivacyPage.typesOfDataProcessing.title')}
                    content={[
                        {text: t('dataPrivacyPage.typesOfDataProcessing.textSections.s1')},
                        {text: t('dataPrivacyPage.typesOfDataProcessing.textSections.s2')},
                        {text: t('dataPrivacyPage.typesOfDataProcessing.textSections.s3')},
                    ]}
                />
                <ContentSection
                    id="legal-basis-for-data-processing"
                    title={t('dataPrivacyPage.legalBasisForDataProcessing.title')}
                    content={[
                        {text: t('dataPrivacyPage.legalBasisForDataProcessing.textSections.s1')},
                        [
                            t('dataPrivacyPage.legalBasisForDataProcessing.textSections.s2'),
                            t('dataPrivacyPage.legalBasisForDataProcessing.textSections.s3'),
                            t('dataPrivacyPage.legalBasisForDataProcessing.textSections.s4'),
                            t('dataPrivacyPage.legalBasisForDataProcessing.textSections.s5'),
                            t('dataPrivacyPage.legalBasisForDataProcessing.textSections.s6'),
                        ],
                    ]}
                />
                <ContentSection
                    id="purposes-of-data-processing"
                    title={t('dataPrivacyPage.purposesOfDataProcessing.title')}
                    content={[
                        {text: t('dataPrivacyPage.purposesOfDataProcessing.textSections.s1')},
                        [
                            t('dataPrivacyPage.purposesOfDataProcessing.textSections.s2'),
                            t('dataPrivacyPage.purposesOfDataProcessing.textSections.s3'),
                            t('dataPrivacyPage.purposesOfDataProcessing.textSections.s4'),
                            t('dataPrivacyPage.purposesOfDataProcessing.textSections.s5'),
                            t('dataPrivacyPage.purposesOfDataProcessing.textSections.s6'),
                            t('dataPrivacyPage.purposesOfDataProcessing.textSections.s7'),
                            t('dataPrivacyPage.purposesOfDataProcessing.textSections.s8'),
                        ],
                        {text: t('dataPrivacyPage.purposesOfDataProcessing.textSections.s9')},
                    ]}
                />
                <ContentSection
                    id="user-rights"
                    title={t('dataPrivacyPage.userRights.title')}
                    content={[
                        {text: t('dataPrivacyPage.userRights.textSections.s1')},
                        [
                            t('dataPrivacyPage.userRights.textSections.s2'),
                            t('dataPrivacyPage.userRights.textSections.s3'),
                            t('dataPrivacyPage.userRights.textSections.s4'),
                            t('dataPrivacyPage.userRights.textSections.s5'),
                            t('dataPrivacyPage.userRights.textSections.s6'),
                            t('dataPrivacyPage.userRights.textSections.s7'),
                            t('dataPrivacyPage.userRights.textSections.s8'),
                            t('dataPrivacyPage.userRights.textSections.s9'),
                        ],
                        {text: t('dataPrivacyPage.userRights.textSections.s10')},
                        {text: t('dataPrivacyPage.userRights.textSections.s11')},
                    ]}
                />
                <ContentSection
                    id="cookies"
                    title={t('dataPrivacyPage.cookies.title')}
                    content={[{text: t('dataPrivacyPage.cookies.text')}]}
                />
                <ContentSection
                    id="social-media"
                    title={t('dataPrivacyPage.socialMedia.title')}
                    content={[{text: t('dataPrivacyPage.socialMedia.text')}]}
                />
                <ContentSection
                    id="not-included"
                    title={t('dataPrivacyPage.notIncluded.title')}
                    content={[{text: t('dataPrivacyPage.notIncluded.text')}]}
                />
                <ContentSection
                    id="changes"
                    title={t('dataPrivacyPage.changes.title')}
                    content={[{text: t('dataPrivacyPage.changes.text')}]}
                />
                <ContentSection
                    id="consent"
                    title={t('dataPrivacyPage.consent.title')}
                    content={[{text: t('dataPrivacyPage.consent.text')}]}
                />
                <p className={classes.timestamp}>{t('dataPrivacyPage.lastUpdatedOn')}</p>
            </div>
            <Contact headline={t('common.contactSection.headline')} name={"Benedikt Lüth"}
                     picture={"/assets/images/team/benedikt-lueth.jpg"}
                     role={t('dataPrivacyPage.operatorAndResponsible.responsibleForDataPrivacy.title')}
                     email={"support@gymble.fit"}/>
        </div>
    );
}

export default DataPrivacy;
