import React, {useEffect} from 'react';
import {createUseStyles} from 'react-jss';
import {useTranslation} from 'react-i18next';
import ArgumentSection from "../components/Common/Arguments/ArgumentSection";
import DownloadSection from "../components/Common/Download/DownloadSection";
import QaSection from "../components/Common/Qa/QaSection";
import InfluencerTestimonialSection
    from "../components/ForInfluencer/InfluencerTestimonials/InfluencerTestimonialSection";
import Contact from "../components/Common/Contact";
import KeyFiguresHeading from "../components/Common/Heading/KeyFiguresHeading";

const useStyles = createUseStyles({
    section: {
        margin: 'var(--margin-very-large) var(--margin-side)',
    },
});

const ForInfluencer = () => {
    const {t} = useTranslation();
    const classes = useStyles();

    const keyFigures = [
        {number: t('keyFigures.kf4.number'), description: t('keyFigures.kf4.name')},
        {number: t('keyFigures.kf3.number'), description: t('keyFigures.kf3.name')},
        {number: t('keyFigures.kf2.number'), description: t('keyFigures.kf2.name')},
        {number: t('keyFigures.kf1.number'), description: t('keyFigures.kf1.name')},
    ];

    const argumentsData = [
        {
            icon: t('forInfluencerPage.argumentSection.arguments.a1.icon'),
            headline: t('forInfluencerPage.argumentSection.arguments.a1.headline'),
            text: t('forInfluencerPage.argumentSection.arguments.a1.subHeadline'),
        },
        {
            icon: t('forInfluencerPage.argumentSection.arguments.a2.icon'),
            headline: t('forInfluencerPage.argumentSection.arguments.a2.headline'),
            text: t('forInfluencerPage.argumentSection.arguments.a2.subHeadline'),
        },
        {
            icon: t('forInfluencerPage.argumentSection.arguments.a3.icon'),
            headline: t('forInfluencerPage.argumentSection.arguments.a3.headline'),
            text: t('forInfluencerPage.argumentSection.arguments.a3.subHeadline'),
        }
    ];

    const qaData = [
        {
            question: t('qa.qaData.questions.q1'),
            answer: t('qa.qaData.answers.a1')
        },
        {
            question: t('qa.qaData.questions.q10'),
            answer: t('qa.qaData.answers.a10')
        },
        {
            question: t('qa.qaData.questions.q7'),
            answer: t('qa.qaData.answers.a7')
        },
        {
            question: t('qa.qaData.questions.q6'),
            answer: t('qa.qaData.answers.a6')
        },
        {
            question: t('qa.qaData.questions.q8'),
            answer: t('qa.qaData.answers.a8')
        },
        {
            question: t('qa.qaData.questions.q9'),
            answer: t('qa.qaData.answers.a9')
        },
    ];

    useEffect(() => {
        document.title = t('forInfluencerPage.title')
    });

    return (
        <div>
            <div id="start">
                <KeyFiguresHeading
                    headline={t('forInfluencerPage.headline')}
                    subHeadline={t('forInfluencerPage.subHeadline')}
                    gifSrc="/assets/images/Feed.gif"
                    keyFigures={keyFigures}
                />
            </div>
            <div className={classes.section}>
                <ArgumentSection argumentsData={argumentsData}/>
            </div>
            <div className={classes.section}>
                <InfluencerTestimonialSection/>
            </div>
            <div className={classes.section}>
                <DownloadSection/>
            </div>
            <div className={classes.section}>
                <QaSection qaData={qaData}/>
            </div>
            <div className={classes.section}>
                <Contact headline={t('common.contactSection.headline')} name={"Benedikt Lüth"}
                         picture={"/assets/images/team/benedikt-lueth.jpg"}
                         role={"Community Manager"} email={"support@gymble.fit"}/>
            </div>
        </div>
    );
}

export default ForInfluencer;