import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import DownloadButton from "../components/Common/Download/DownloadButton";
import AppOverviewSection from "../components/Common/AppOverviewSection";
import DownloadSection from "../components/Common/Download/DownloadSection";
import KeyFiguresHeading from "../components/Common/Heading/KeyFiguresHeading";
import {createUseStyles} from "react-jss";
import QaSection from "../components/Common/Qa/QaSection";

const useStyles = createUseStyles({
    section: {
        margin: 'var(--margin-very-large) var(--margin-side)'
    },
});

const Download = () => {
    const {t} = useTranslation();
    const classes = useStyles();

    const keyFigures = [
        {number: t('keyFigures.kf4.number'), description: t('keyFigures.kf4.name')},
        {number: t('keyFigures.kf3.number'), description: t('keyFigures.kf3.name')},
        {number: t('keyFigures.kf2.number'), description: t('keyFigures.kf2.name')},
        {number: t('keyFigures.kf1.number'), description: t('keyFigures.kf1.name')},
    ];

    const qaData = [
        {
            question: t('qa.qaData.questions.q1'),
            answer: t('qa.qaData.answers.a1')
        },
        {
            question: t('qa.qaData.questions.q2'),
            answer: t('qa.qaData.answers.a2')
        },
        {
            question: t('qa.qaData.questions.q4'),
            answer: t('qa.qaData.answers.a4')
        },
        {
            question: t('qa.qaData.questions.q11'),
            answer: t('qa.qaData.answers.a11')
        },
        {
            question: t('qa.qaData.questions.q12'),
            answer: t('qa.qaData.answers.a12')
        },
        {
            question: t('qa.qaData.questions.q14'),
            answer: t('qa.qaData.answers.a14')
        },
    ];

    useEffect(() => {
        document.title = t('downloadPage.title')
    });

    return (
        <div>
            <div id="start">
                <KeyFiguresHeading
                    headline={t('downloadPage.headline')}
                    subHeadline={t('downloadPage.subHeadline')}
                    buttons={[<DownloadButton store={"appStore"}/>, <DownloadButton store={"playStore"}/>]}
                    gifSrc="/assets/images/Feed.gif"
                    keyFigures={keyFigures}
                />
            </div>
            <div className={classes.section}>
                <AppOverviewSection/>
            </div>
            <div className={classes.section}>
                <DownloadSection/>
            </div>
            <div className={classes.section}>
                <QaSection qaData={qaData}/>
            </div>
        </div>
    );
};

export default Download;
