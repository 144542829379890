import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import {Autoplay, EffectCube} from 'swiper/modules';
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    swiper: {
        width: 'auto',
        height: 'auto',
    },
    swiperSlide: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '-webkit-max-content!important',
        background: 'transparent'
    },
});

const TextCarousel = ({
                          texts,
                          autoplayDelay = 5000,
                          slideClassName = "",
                      }) => {
    const classes = useStyles();

    return (
        <>
            <Swiper
                effect={'cube'}
                loop={true}
                direction={'vertical'}
                slidesPerView={1}
                modules={[Autoplay, EffectCube]}
                className={classes.swiper}
                autoplay={{
                    delay: autoplayDelay,
                    disableOnInteraction: false,
                    reverseDirection: true
                }}
                cubeEffect={{
                    shadow: false,
                    slideShadows: false,
                }}
                autoHeight={true}
                allowTouchMove={false}
            >
                {texts.map((text, index) => (
                    <SwiperSlide key={index} className={`${classes.swiperSlide} ${slideClassName}`}>
                        {text}
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    );
}

export default TextCarousel;