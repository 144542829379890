import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import {Autoplay} from 'swiper/modules';
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    swiper: {
        width: '100%',
        height: 'auto',
    },
    swiperSlide: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

const Carousel = ({
                      swiperSlideComponent,
                      data,
                      autoplay = true,
                      autoplayDelay = 5000,
                      direction = 'horizontal',
                      slidesPerViewMobile = 1,
                      slidesPerViewDesktop = 3,
                  }) => {
    const classes = useStyles();

    return (
        <>
            <Swiper
                grabCursor={true}
                loop={true}
                direction={direction}
                spaceBetween={'20px'}
                breakpoints={{
                    768: {
                        slidesPerView: slidesPerViewMobile,
                    },
                    1200: {
                        slidesPerView: slidesPerViewDesktop,
                    },
                }}
                modules={[Autoplay]}
                className={classes.swiper}
                autoplay={autoplay && {
                    delay: autoplayDelay,
                    disableOnInteraction: false,
                }}
            >
                {data.map((item, index) => (
                    <SwiperSlide key={index} className={classes.swiperSlide}>
                        {React.createElement(swiperSlideComponent, {...item})}
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    );
}

export default Carousel;
