import React, {useEffect} from 'react';
import {createUseStyles} from 'react-jss';
import {useTranslation} from 'react-i18next';
import VideoHeading from '../components/Common/Heading/VideoHeading';
import AppOverviewSection from '../components/Common/AppOverviewSection';
import DownloadSection from "../components/Common/Download/DownloadSection";
import QaSection from "../components/Common/Qa/QaSection";
import ReviewSection from "../components/Home/Reviews/ReviewSection";

const useStyles = createUseStyles({
    section: {
        margin: 'var(--margin-very-large) var(--margin-side)'
    },
});

const Home = () => {
    const {t} = useTranslation();
    const classes = useStyles();

    const keyFigures = [
        {number: t('keyFigures.kf4.number'), description: t('keyFigures.kf4.name')},
        {number: t('keyFigures.kf3.number'), description: t('keyFigures.kf3.name')},
        {number: t('keyFigures.kf2.number'), description: t('keyFigures.kf2.name')},
        {number: t('keyFigures.kf1.number'), description: t('keyFigures.kf1.name')},
    ];

    const qaData = [
        {
            question: t('qa.qaData.questions.q1'),
            answer: t('qa.qaData.answers.a1')
        },
        {
            question: t('qa.qaData.questions.q2'),
            answer: t('qa.qaData.answers.a2')
        },
        {
            question: t('qa.qaData.questions.q3'),
            answer: t('qa.qaData.answers.a3')
        },
        {
            question: t('qa.qaData.questions.q4'),
            answer: t('qa.qaData.answers.a4')
        },
        {
            question: t('qa.qaData.questions.q11'),
            answer: t('qa.qaData.answers.a11')
        },
        {
            question: t('qa.qaData.questions.q12'),
            answer: t('qa.qaData.answers.a12')
        },
        {
            question: t('qa.qaData.questions.q13'),
            answer: t('qa.qaData.answers.a13')
        },
        {
            question: t('qa.qaData.questions.q5'),
            answer: t('qa.qaData.answers.a5')
        },
        {
            question: t('qa.qaData.questions.q14'),
            answer: t('qa.qaData.answers.a14')
        },
    ];

    useEffect(() => {
        document.title = t('homePage.title')
    });

    return (
        <div>
            <div id="start">
                <VideoHeading videoSrc={"/assets/videos/intro.mp4"} keyFigures={keyFigures}/>
            </div>
            <div id="app" className={classes.section}>
                <AppOverviewSection/>
            </div>
            <div className={classes.section}>
                <ReviewSection/>
            </div>
            <div id="download" className={classes.section}>
                <DownloadSection/>
            </div>
            <div id="q&a" className={classes.section}>
                <QaSection qaData={qaData}/>
            </div>
        </div>
    );
}

export default Home;