import React from 'react';
import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
    keyFigure: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: 'var(--white)',
    },
    number: {
        fontSize: 'var(--font-size-large)',
        fontWeight: 'bold',
    },
    description: {
        fontSize: 'var(--font-size-small)',
    }
});

const KeyFigure = ({number, description}) => {
    const classes = useStyles();

    return (
        <div className={classes.keyFigure}>
            <div className={classes.number}>
                {number}
            </div>
            <div className={classes.description}>
                {description}
            </div>
        </div>
    );
};

export default KeyFigure;