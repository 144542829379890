import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './config/i18n.js'
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Home from './pages/Home';
import ForInfluencer from "./pages/ForInfluencer";
import Download from "./pages/Download";
import Imprint from "./pages/Imprint";
import CookieConsent from "./components/Cookies/CookieConstent";
import DataPrivacy from "./pages/DataPrivacy";
import TermsOfUse from "./pages/TermsOfUse";
import CommunityGuidlines from "./pages/CommunityGuidlines";

function App() {
    return (
        <Router>
            <div className="App">
                <Navbar/>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/for-influencer" element={<ForInfluencer/>}/>
                    <Route path="/download" element={<Download/>}/>
                    <Route path="/imprint" element={<Imprint/>}/>
                    <Route path="/data-privacy" element={<DataPrivacy/>}/>
                    <Route path="/terms-of-use" element={<TermsOfUse/>}/>
                    <Route path="/community-guidelines" element={<CommunityGuidlines/>}/>
                </Routes>
                <Footer/>
                <CookieConsent/>
            </div>
        </Router>
    );
}

export default App;