import React from 'react';
import {createUseStyles} from 'react-jss';
import {faInstagram, faLinkedin, faReddit, faTiktok} from "@fortawesome/free-brands-svg-icons";
import {useTranslation} from "react-i18next";
import LocaleSwitcher from "./LocaleSwitcher";
import IconButton from "../Common/IconButton";
import {HashLink} from "react-router-hash-link";

const useStyles = createUseStyles({
    footer: {
        backgroundColor: 'var(--black)',
        color: 'var(--grey)',
        fontSize: 'var(--font-size-small)',
        margin: 'var(--margin-large) 0',
        textAlign: 'center',
    },
    footerContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 'var(--margin-standard)',
        '@media (max-width: 900px)': {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    },
    logo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginLeft: 'var(--margin-side)',
        height: '35px',
    },
    socialIcons: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginLeft: 'calc(var(--margin-side) - var(--padding-standard))',
    },
    linksContainer: {
        fontWeight: 'bold',
        fontSize: 'var(--font-size-small)',
        display: 'flex',
        justifyContent: 'center',
        marginRight: 'calc(var(--margin-side) - var(--margin-small) - var(--padding-standard))',
        '@media (max-width: 900px)': {
            flexDirection: 'column',
            alignItems: 'flex-start',
            marginLeft: 'calc(var(--margin-side) - var(--margin-small) - var(--padding-standard))',
            marginTop: 'var(--margin-standard)',
        },
    },
    link: {
        color: 'var(--grey)',
        alignItems: 'center',
        padding: 'var(--padding-standard)',
        margin: '0 var(--margin-small)',
        position: 'relative',
        textDecoration: 'none',
        '&::before': {
            content: '""',
            width: '0%',
            position: 'absolute',
            height: '2px',
            backgroundColor: 'var(--grey)',
            bottom: 'calc(var(--padding-standard) - var(--margin-very-small))',
            left: 'var(--padding-standard)',
            transition: 'width 0.3s ease',
        },
        '&:hover::before': {
            width: 'calc(100% - 2 * var(--padding-standard))',
        },
    },
    localeSwitcher: {
        marginRight: 'calc(var(--margin-side) - var(--padding-standard))',
        '@media (max-width: 900px)': {
            marginLeft: 'calc(var(--margin-side) - var(--padding-standard))',
            marginTop: 'var(--margin-standard)',
        }
    }
});

const Footer = () => {
    const {t} = useTranslation();
    const classes = useStyles();

    return (
        <footer className={classes.footer}>
            <div className={classes.footerContent}>
                <img src="/assets/images/logo/logo_horizontal.png" alt="Logo" className={classes.logo}/>
                <div className={classes.linksContainer}>
                    <HashLink smooth to="/imprint#website-operator"
                              className={classes.link}>{t('common.footer.imprint')}</HashLink>
                    <HashLink smooth to="/data-privacy#operator-and-responsible"
                              className={classes.link}>{t('common.footer.dataPrivacy')}</HashLink>
                    <HashLink smooth to="/terms-of-use#introduction"
                              className={classes.link}>{t('common.footer.termsOfUse')}</HashLink>
                    <HashLink smooth
                              to="/community-guidelines#abridged-version"
                              className={classes.link}>{t('common.footer.communityGuidelines')}</HashLink>
                    <a href="mailto:support@gymble.fit" className={classes.link}>{t('common.footer.support')}</a>
                </div>
            </div>
            <div className={classes.footerContent}>
                <div className={classes.socialIcons}>
                    <IconButton icon={faTiktok} href="https://www.tiktok.com/" color="var(--grey)"/>
                    <IconButton icon={faInstagram} href="https://www.instagram.com/" color="var(--grey)"/>
                    <IconButton icon={faReddit} href="https://www.reddit.com/" color="var(--grey)"/>
                    <IconButton icon={faLinkedin} href="https://www.linkedin.com/" color="var(--grey)"/>
                </div>
                <div className={classes.localeSwitcher}>
                    <LocaleSwitcher/>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
