import React from 'react';
import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
    h2: {
        fontSize: 'var(--font-size-standard)',
        fontWeight: 'bold',
        margin: 'var(--margin-standard) 0',
    },
    p: {
        fontSize: 'var(--font-size-small)',
    },
    pNoMargin: {
        margin: 0,
    },
    strong: {
        fontWeight: 'bold'
    },
    keyPoint: {
        margin: 'var(--margin-small) 0',
    },
});

const ContentSection = ({id, title, content}) => {
    const classes = useStyles();

    return (
        <div id={id}>
            <h2 className={classes.h2}>{title}</h2>
            {content.map((item, index) => (
                <React.Fragment key={index}>
                    {Array.isArray(item) ? (
                        <ul>
                            {item.map((point, idx) => (
                                <li key={idx} className={`${classes.p} ${classes.keyPoint}`}>
                                    {point}
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p className={
                            `
                            ${classes.p} 
                            ${item.isStrong ? classes.strong : ''} 
                            ${item.hasNoMargin ? classes.pNoMargin : ''}
                            `
                        }>
                            {item.text}
                        </p>
                    )}
                </React.Fragment>
            ))}
        </div>
    );
};

export default ContentSection;
