import React from 'react';
import {createUseStyles} from 'react-jss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const useStyles = createUseStyles({
    icon: {
        alignItems: 'center',
        justifyContent: 'center',
        padding: 'var(--padding-standard)',
        position: 'relative',
    },
});

const IconButton = ({
                        icon,
                        color = 'var(--white)',
                        size = 'var(--icon-size-standard)',
                        href,
                        target = '_blank',
                        rel = 'noreferrer',
                    }) => {
    const classes = useStyles();

    return (
        <a href={href} target={target} rel={rel}>
            <FontAwesomeIcon icon={icon} className={classes.icon} style={{color, fontSize: size}}/>
        </a>
    );
};

export default IconButton;
