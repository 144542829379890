import i18n from 'i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import {initReactI18next} from 'react-i18next';

export const supportedLngs = {
    en: "English",
    de: "Deutsch",
    es: "Español",
    fr: "Français",
    ru: "Русский",
    pt: "Português",
    tr: "Türkçe",
    it: "Italiano",
};

i18n
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        supportedLngs: Object.keys(supportedLngs),
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;