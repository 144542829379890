import React from 'react';
import {createUseStyles} from 'react-jss';
import {useTranslation} from "react-i18next";
import KeyFigureSection from "../KeyFigures/KeyFigureSection";
import TextCarousel from "../Carousel/TextCarousel";

const useStyles = createUseStyles({
    container: {
        position: 'relative',
        width: '100%',
        top: 0,
        height: '100vh',
    },
    video: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    darkOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    textOverlay: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        width: 'calc(100% - 2 * var(--margin-side))',
    },
    staticText: {
        fontSize: 'var(--font-size-super-large)',
        fontWeight: 'bold',
        color: 'var(--white)',
    },
    dynamicText: {
        fontSize: 'var(--font-size-super-large)',
        fontWeight: 'bold',
        color: 'var(--red)',
    },
    keyFigures: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
    },
});

const VideoHeading = ({videoSrc, keyFigures}) => {
    const {t} = useTranslation();
    const classes = useStyles();

    const dynamicTexts = [t('homePage.heading.dynamicText.motivate'), t('homePage.heading.dynamicText.train'), t('homePage.heading.dynamicText.find')];

    return (
        <div className={classes.container}>
            <video className={classes.video} autoPlay loop muted playsInline>
                <source src={videoSrc} type="video/mp4"/>
            </video>
            <div className={classes.darkOverlay}></div>
            <div className={classes.textOverlay}>
                <div className={classes.staticText}>{t('homePage.heading.staticText')}</div>
                <TextCarousel texts={dynamicTexts} slideClassName={classes.dynamicText} autoplayDelay={3500}/>
            </div>
            <div className={classes.keyFigures}>
                <KeyFigureSection keyFigures={keyFigures}/>
            </div>
        </div>
    );
}

export default VideoHeading;