import React, {useEffect} from 'react';
import {createUseStyles} from 'react-jss';
import {useTranslation} from "react-i18next";
import Contact from "../components/Common/Contact";
import ContentSection from "../components/Footer/ContentSection";

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    contentContainer: {
        width: '50%',
        color: 'var(--white)',
        margin: 'var(--navbar-height) var(--margin-side)',
        '@media (max-width: 900px)': {
            width: 'calc(100% - 2*var(--margin-side))',
        },
    },
    h1: {
        fontSize: 'var(--font-size-large)',
        fontWeight: 'bold',
        marginTop: 'var(--margin-large)'
    },
});

const TermsOfUse = () => {
    const {t} = useTranslation();
    const classes = useStyles();

    useEffect(() => {
        document.title = t('termsOfUsePage.title')
    });

    return (
        <div className={classes.container}>
            <div className={classes.contentContainer}>
                <h1 className={classes.h1}>{t('termsOfUsePage.title')}</h1>
                <ContentSection
                    id="introduction"
                    content={[
                        {text: t('termsOfUsePage.introduction.textSections.s1')},
                        {text: t('termsOfUsePage.introduction.textSections.s2')},
                        {text: t('termsOfUsePage.introduction.textSections.s3')},
                        {text: t('termsOfUsePage.introduction.textSections.s4')},
                    ]}
                />
                <ContentSection
                    id="rules-of-conduct"
                    title={t('termsOfUsePage.rulesOfConduct.title')}
                    content={[
                        {text: t('termsOfUsePage.rulesOfConduct.textSections.s1')},
                        {text: t('termsOfUsePage.rulesOfConduct.textSections.s2')},
                        [
                            t('termsOfUsePage.rulesOfConduct.textSections.s3'),
                            t('termsOfUsePage.rulesOfConduct.textSections.s4'),
                            t('termsOfUsePage.rulesOfConduct.textSections.s5'),
                            t('termsOfUsePage.rulesOfConduct.textSections.s6'),
                            t('termsOfUsePage.rulesOfConduct.textSections.s7'),
                            t('termsOfUsePage.rulesOfConduct.textSections.s8'),
                            t('termsOfUsePage.rulesOfConduct.textSections.s9'),
                        ],
                        {text: t('termsOfUsePage.rulesOfConduct.textSections.s10')},
                        {text: t('termsOfUsePage.rulesOfConduct.textSections.s11')},
                        {text: t('termsOfUsePage.rulesOfConduct.textSections.s12')},
                        {text: t('termsOfUsePage.rulesOfConduct.textSections.s13')},
                    ]}
                />
                <ContentSection
                    id="intellectual-property"
                    title={t('termsOfUsePage.intellectualProperty.title')}
                    content={[
                        {text: t('termsOfUsePage.intellectualProperty.textSections.s1')},
                        {text: t('termsOfUsePage.intellectualProperty.textSections.s2')},
                    ]}
                />
                <ContentSection
                    id="third-party-websites"
                    title={t('termsOfUsePage.thirdPartyWebsites.title')}
                    content={[
                        {text: t('termsOfUsePage.thirdPartyWebsites.textSections.s1')},
                        {text: t('termsOfUsePage.thirdPartyWebsites.textSections.s2')},
                    ]}
                />
                <ContentSection
                    id="liability"
                    title={t('termsOfUsePage.liability.title')}
                    content={[
                        {text: t('termsOfUsePage.liability.textSections.s1')},
                        {text: t('termsOfUsePage.liability.textSections.s2')},
                    ]}
                />
                <ContentSection
                    id="term-and-termination"
                    title={t('termsOfUsePage.termAndTermination.title')}
                    content={[
                        {text: t('termsOfUsePage.termAndTermination.textSections.s1')},
                        {text: t('termsOfUsePage.termAndTermination.textSections.s2')},
                    ]}
                />
                <ContentSection
                    id="transmission"
                    title={t('termsOfUsePage.transmission.title')}
                    content={[
                        {text: t('termsOfUsePage.transmission.textSections.s1')},
                    ]}
                />
                <ContentSection
                    id="law"
                    title={t('termsOfUsePage.law.title')}
                    content={[
                        {text: t('termsOfUsePage.law.textSections.s1')},
                        {text: t('termsOfUsePage.law.textSections.s2')},
                    ]}
                />
            </div>
            <Contact headline={t('common.contactSection.headline')} name={"Benedikt Lüth"}
                     picture={"/assets/images/team/benedikt-lueth.jpg"}
                     role={'Community Manager'}
                     email={"support@gymble.fit"}/>
        </div>
    );
}

export default TermsOfUse;
