import React from 'react';
import {HashLink} from 'react-router-hash-link';
import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
    menuItem: {
        color: 'var(--white)',
        textDecoration: 'none',
        display: 'flex',
        flexDirection: 'row',
        padding: 'var(--padding-standard)',
        alignItems: 'flex-start',
        borderColor: 'transparent',
        transition: 'border-color 0.3s, color 0.3s',
        fontSize: 'var(--font-size-small)',
        fontWeight: 'bold',
        '&.overlay': {
            fontSize: 'var(--font-size-large)',
            fontWeight: 'normal',
            marginTop: 'var(--margin-standard)',
            marginLeft: 'calc(var(--margin-side) - var(--padding-standard))',
        },
        '&.button': {
            backgroundColor: 'var(--red)',
            padding: 'var(--padding-standard)',
            borderRadius: '15px',
            fontWeight: 'bold',
            '&:hover': {
                filter: 'brightness(0.9)',
            },
            '@media (max-width: 900px)': {
                borderRadius: '10px',
            }
        },
        '&:not(.button):hover': {
            color: 'var(--red)',
        },
    },
    icon: {
        marginRight: 'var(--margin-small)',
    },
});

const MenuItem = ({icon, text, to, isOverlay = false, isButton = false}) => {
    const classes = useStyles();

    let menuItemClass = classes.menuItem;
    if (isOverlay) menuItemClass += ' overlay';
    if (isButton) menuItemClass += ' button';

    return (
        <HashLink smooth to={to} className={menuItemClass}>
            {icon && <div className={classes.icon}>{icon}</div>}
            <div className={classes.menuItemText}>{text}</div>
        </HashLink>
    );
}

export default MenuItem;
